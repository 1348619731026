
import { defineComponent, onMounted, ref, watch } from "vue";
import { ScrollComponent } from "../../../assets/ts/components";
import { setCurrentPageTitle } from "../../../helper/Breadcrumb";
import MessageIn from "@/presentation/components/messenger-parts/MessageIn.vue";
import MessageOut from "@/presentation/components/messenger-parts/MessageOut.vue";
import { SessionListModel } from "@/domain/hotel-management/session-list/model/SessionListModel";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { useI18n } from "vue-i18n";
import { SendMessageModel } from "@/domain/hotel-management/send-message/model/SendMessageModel";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { RetryAnswerModel } from "@/domain/hotel-management/retry-answer/model/RetryAnswerModel";
import i18n from "@/presentation/language/i18n";

interface SessionMessages {
  type: string;
  name?: string;
  image?: string;
  time: string;
  text: string;
  messageId?: number;
  emotionAnalysis?: Object;
}

export default defineComponent({
  name: "new-session",
  components: {
    MessageIn,
    MessageOut,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    const messagesRef = ref<null | HTMLElement>(null);
    const messagesInRef = ref<null | HTMLElement>(null);
    const messagesOutRef = ref<null | HTMLElement>(null);

    const swalNotification = new SwalNotification();

    const sentMessage = ref<number>(0);
    // 0 işlem bitti veya başlamadı, 1 beklemede,

    const isSessionError = ref<boolean>(false);
    const sessionClosed = ref<boolean>(false);
    const newSession = ref<number>(0);

    const messages = ref<Array<SessionMessages>>([]);

    const sessionList = ref<SessionListModel[]>([]);
    const sessionController = store.state.ControllersModule.sessionController;

    const newMessageText = ref("");

    const thisSession = ref<SendMessageModel>({
      session: {
        productSessionMessages: [],
      },
    });

    function AIMessageFlow(text: string | any, messagesRef: SessionMessages[]) {
      let count = 0;
      let artis = 5;
      let interval = setInterval(() => {
        if (count >= text.length || !messagesRef.length)
          clearInterval(interval);

        const lastMessageIndex = messages.value.length - 1;

        if (lastMessageIndex >= 0 && lastMessageIndex < messagesRef.length) {
          messagesRef[lastMessageIndex].text = text.slice(0, count);
        }
        count += artis;
        ScrollComponent.updateAll();
      }, 100);
    }

    const addNewMessage = () => {
      if (!newMessageText.value) {
        Swal.fire({
          text: "Lütfen boş mesaj göndermeyiniz.",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: t("common.button.ok"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return;
      } else {
        if (messages.value.length == 0) {
          messages.value.push({
            type: "out",
            time: "Az önce",
            text: newMessageText.value,
          });

          const sendButton = document.querySelector(
            '.btn[data-kt-element="send"]'
          );
          if (sendButton) {
            sendButton.setAttribute("disabled", "disabled");
          }

          const sendMessageModel: SendMessageModel = {
            text: newMessageText.value,
            session: {
              productSessionMessages: [],
            },
          };
          newMessageText.value = "";

          sentMessage.value = 1;

          sendMessage(sendMessageModel);
          return;
        } else {
          Swal.fire({
            text: t("modals.hotel.closedSession"),
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: t("common.button.ok"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return;
        }
      }
    };

    const sendMessage = async (sendMessageModel) => {
      if (
        newMessageText.value != "" ||
        newMessageText.value != null ||
        newMessageText.value != undefined
      ) {
        sessionController
          .sendMessage(sendMessageModel)
          .then((response) => {
            if (response.isSuccess) {
              newSession.value += 1;
              sentMessage.value = 0;
              store.dispatch("newChat", newSession.value);

              thisSession.value = response.getValue();
              let length =
                thisSession.value.session.productSessionMessages.length;

                messages.value = thisSession.value.session.productSessionMessages.map((msg) => ({
                  type: msg.owner === "AI" ? "in" : "out",
                  name: msg.owner === "AI" ? "Asistan" : "Sen",
                  image:
                  msg.owner === "USER"
                          ? "/media/avatars/blank.png"
                          : "/media/avatars/ai.jpg",
            time: "Az önce",
            text: msg.text,
            messageId: msg.messageId,
            emotionAnalysis: msg.emotionAnalysis
          }));

              sessionClosed.value = true;
              ScrollComponent.updateAll();
              AIMessageFlow(
                thisSession.value.session.productSessionMessages[length - 1]
                  .text,
                messages.value
              );

              /*
              setTimeout(() => {
                Swal.fire({
                  text: t(SWAL_MESSAGES.SESSION_CLOSED),
                  icon: "success",
                  buttonsStyling: false,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }, 200);
              */
            } else {
              isSessionError.value = true;
              swalNotification.error(
                response.error.cause.cause,
                t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
              );
            }
          })
          .catch((e) => {
            isSessionError.value = true;
            swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          });
      } else {
        Swal.fire({
          text: t("modals.hotel.emptyMessage"),
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: t("common.button.ok"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then(() => {});
      }
      setTimeout(() => {
        if (messagesRef.value) {
          messagesRef.value.scrollTop = messagesRef.value.scrollHeight;
        }
      }, 1);
    };

    const retryAnswer = (messageId) => {
      sentMessage.value = 1;
      const retryAnswerModel: RetryAnswerModel = {
        messageId: Number(messageId),
      };

      sessionController
        .retryAnswer(retryAnswerModel)
        .then((response) => {
          if (response.isSuccess) {
            newSession.value += 1;
            sentMessage.value = 0;
            store.dispatch("newChat", newSession.value);
            
            messages.value.push({
              type: "in",
              name: "Asistan",
              time: "Az önce",
              text: response.getValue().text,
                messageId:
                response.getValue().messageId,
              });

            ScrollComponent.updateAll();
            AIMessageFlow(
              response.getValue()
                  .text,
                messages.value
              );

            sessionClosed.value = true;

          } else {
            isSessionError.value = true;
            swalNotification.error(
              response.error.cause.cause,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          isSessionError.value = true;
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const formatDate = (isoDate) => {
      const date = new Date(isoDate);
      const now = new Date();

      const difference = now.getTime() - date.getTime();

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));

      if (days === 0) {
        const hours = Math.floor(difference / (1000 * 60 * 60));
        if (hours === 0) {
          const minutes = Math.floor(difference / (1000 * 60));
          return `${minutes} ` + i18n.global.t("common.times.minuteAgo");
        } else {
          return `${hours} ` + i18n.global.t("common.times.hourAgo");
        }
      } else {
        return `${days} ` + i18n.global.t("common.times.dayAgo");
      }
    };

    watch(
      () => store.getters.startNewSession,
      () => {
        if (store.getters.startNewSession) cleanSessionForNewSession();
      }
    );

    const cleanSessionForNewSession = () => {
      messages.value = [];
      sessionClosed.value = false;
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.hotel.title"));

      if (!messagesRef.value) {
        return;
      }

      ScrollComponent.bootstrap();
    });

    return {
      formatDate,
      messages,
      messagesRef,
      newMessageText,
      addNewMessage,
      messagesInRef,
      messagesOutRef,
      sessionList,
      sentMessage,
      isSessionError,
      sessionClosed,
      retryAnswer,
    };
  },
});
